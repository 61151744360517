const Vertical = {
  Upper: 0,
  Lower: 1,
};
export { Vertical };

const Horizontal = {
  Left: 0,
  Center: 1,
  Right: 2,
};
export { Horizontal };
